<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex-grow lg:h-0 relative">
      <swiper
        :modules="modules"
        class="h-[400px] lg:h-full bg-gray-200"
        :zoom="true"
        :space-between="0"
        @swiper="onSwiper"
        :thumbs="{ swiper: thumbs }"
        @zoomChange="onZoomChange"
        :watchSlidesProgress="true"
        :style="{
          '--swiper-pagination-color': '#fff',
        }"
      >
        <!-- :preventClicks="true"
        :preventClicksPropagation="true" -->
        <swiper-slide v-for="image in images_" :key="image">
          <div class="swiper-zoom-container h-full w-full" data-swiper-zoom="4">
            <img
              class="w-full h-full object-contain"
              :src="$filters.uploadUrl(image)"
              alt=""
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="px-4 py-4 flex-none">
      <div class="py-2 flex">
        <a href="#" @click.prevent="toggleZoom" class="inline-flex">
          <i class="mr-2">
            <img src="@/assets/zoom-in.svg" alt="" />
          </i>
          放大或縮小上方圖片
        </a>
        <a
          href="#"
          class="ml-auto"
          @click.prevent="
            $modal.toggle({
              component: 'Visit',
            })
          "
        >
          <i class="">
            <img src="@/assets/howto.svg" alt="" />
          </i>
        </a>
      </div>
      <swiper
        @swiper="setThumbs"
        :modules="modules"
        :spaceBetween="10"
        :slidesPerView="'auto'"
        :updateOnImagesReady="true"
        :watchSlidesProgress="true"
      >
        <swiper-slide
          v-for="image in images_"
          :key="image"
          class="!w-10 !h-10 lg:!w-14 lg:!h-14"
        >
          <img
            class="object-cover w-full h-full"
            :src="$filters.uploadUrl(image, 'sm')"
            alt=""
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {inject, onMounted, ref, watch, computed, toRefs } from 'vue';
import { Thumbs, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import Track from '@/utils/Track';
import { getThumb } from '@/utils/Utils';

export default {
  props: {
    images: {
      type: Array,
    },
  },
  components: { Swiper, SwiperSlide },
  setup(props) {
    // const exhibId = inject('exhibId');

    const swiperInstance = ref();

    const zoom = ref(false);
    watch(zoom, (zoom) => {
      swiperInstance.value.allowTouchMove = !zoom;
    });
    const { images } = toRefs(props);
    const images_ = computed(() => {
      if (!images.value) return [];
      return images.value.map((x) => {
        return x.pic;
      });
    });
    const onSwiper = (swiper) => {
      swiperInstance.value = swiper;
      setTimeout(() => {
        swiperInstance.value.update();
      }, 1500);
    };

    const onZoomChange = (e, scale) => {
      zoom.value = scale > 1;
    };

    const slideTo = (idx) => {
      if (zoom.value) {
        swiperInstance.value.zoom.out();
        setTimeout(() => {
          swiperInstance.value.slideTo(idx);
        }, 300);
      } else {
        swiperInstance.value.slideTo(idx);
      }
    };

    const toggleZoom = () => {
      if (zoom.value) {
        swiperInstance.value.zoom.out();
      } else {
        swiperInstance.value.zoom.in();
      }
    };

    let thumbs = ref(null);
    const setThumbs = (swiper) => {
      thumbs.value = swiper;
    };
    onMounted(() => {
      Track({
        ea: '2d_detail',
        ec: 'exhibition',
        el: '',
      });
    });

    // });
    return {
      images_,
      toggleZoom,

      zoom,
      thumbs,
      setThumbs,
      onSwiper,
      slideTo,
      onZoomChange,
      getThumb,
      modules: [Thumbs, Zoom],
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-image) {
  img {
    @apply object-contain;
  }
}

.gallery {
  height: calc(100% - 100px);
}
</style>

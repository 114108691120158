import { createApp } from 'vue';

import debounce from '@/utils/debouncer';
import MainMenu from '@/partials/MainMenu.vue';
import Counter from '@/partials/Counter.vue';
import History from '@/partials/History.vue';
import Detail from '@/partials/detail/Index.vue';
import SearchForm from '@/partials/SearchForm.vue';
import AppFooter from '@/partials/AppFooter.vue';
import AppNecker from '@/partials/necker.vue';
import BtnEnter from '@/partials/BtnEnter.vue';
import Materials from '@/partials/Materials.vue';
import AppLogin from '@/partials/Login.vue';
import StatusChecker from '@/partials/StatusChecker.vue';
import User from '@/partials/User.vue';

import Modal from '@/partials/Modal.vue';

import '@/assets/element-plus/index.css';
// import 'element-plus/dist/index.css';
import 'element-plus/es/components/select/style/css';
import '@/assets/index.scss';
import { auth, status } from '@/useAuth';

import useStats from '@/useStats';
import { uploadUrl } from '@/utils/Utils';

import TextWrap from '@/utils/TextWrap';
import detectMobile from '@/utils/detectMobile';
import { useRoute } from 'vue-router';
export default () => {
  const app = createApp({
    compilerOptions: {
      delimiters: ['${', '}'],
    },
  });
  TextWrap();

  app.directive('debounce', (el, binding) => debounce(el, binding));
  app.component('MainMenu', MainMenu);
  app.component('Counter', Counter);
  app.component('StatusChecker', StatusChecker);
  app.component('History', History);
  app.component('Materials', Materials);
  app.component('WorkDetail', Detail);
  app.component('SearchForm', SearchForm);
  app.component('AppFooter', AppFooter);
  app.component('AppNecker', AppNecker);
  app.component('BtnEnter', BtnEnter);
  app.component('AppLogin', AppLogin);
  app.component('Modal', Modal);
  app.component('User', User);

  app.config.globalProperties.$filters = {
    uploadUrl,
  };
  window.$filters = app.config.globalProperties.$filters;
  app.config.globalProperties.$state = {
    isMobile: detectMobile(),
    isLandscape: window.innerHeight < window.innerWidth,
  };

  const highlight = (text, keyword) => {
    const result = text.match(new RegExp(keyword, 'i'));

    // true
    return !result
      ? text
      : text.replace(result[0], `<span class="highlight">${result[0]}</span>`);
  };
  app.directive('highlight', {
    mounted(el, binding) {
      if (!binding.value || !el.innerText) return;
      el.innerHTML = highlight(el.innerText, binding.value);
    },
    updated(el, binding) {
      el.innerHTML = highlight(el.innerText, binding.value);
    },
  });

  app.provide('isMobile', detectMobile());

  app.provide('auth', auth);

  const { stats } = useStats();
  app.provide('stats', stats);

  const getRouteName = e => {
    const paths = window.location.pathname.split('/');
    return paths && paths[1] ? paths[1] : '/';
  };

  window.addEventListener('contextmenu', e => {
    const routeName = getRouteName();
    if (routeName !== 'editor') {
      e.preventDefault();
    }
  });
  document.addEventListener('copy', e => {
    const routeName = getRouteName();
    if (routeName !== 'editor') {
      e.preventDefault();
    }
  });

  return app;
};

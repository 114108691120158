import trackService from '@/services/Track';

export const pass = (opt, stat) => {
  let test = false;
  if (test) return;
  trackService.pass({
    ea: opt.ea || 'open',
    ec: opt.ec || 'home', //name
    el: opt.el, //id
    ...stat,
  });
};

export const GTAG = ({ ea, ec, el }) => {
  console.log('track:' + JSON.stringify({ ea, ec, el }));
  window.gtag('event', ea, {
    event_category: ec,
    event_label: el,
  });
};

export default function track(opt) {
  pass(opt);
  const checkStay = setInterval(() => {
    pass({ ea: 'stay' });
  }, 30 * 60 * 1000);

  const clear = () => {
    clearTimeout(checkStay);
  };

  return clear;
}
